import React from "react";
import { BrowserRouter } from "react-router-dom";
import styled from "styled-components";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { CookiesProvider } from 'react-cookie';

import Menu from "./components/MenuComponent";
import Routing from "./components/RoutingComponent";
import Footer from "components/FooterComponent";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00bcb7"
    },
    secondary: {
      main: "#333333",
    },
    text: {
      primary: "#333333"
    }
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: '#e0ffc8',
      },
    },
  },
});

const Container = styled.table`
  height: 100vh;
  border-collapse: collapse;

  & > tbody > tr > td {
    margin: 0;
    padding: 0;
  }
`;

const StyledWrapper = styled.div`
  margin-top: 80px;
  padding: 2em 1em;
`;

export default() => {
  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
            <Menu />
            <Container>
              <tbody>
                <tr>
                  <td valign="top">
                    <StyledWrapper>
                      <Routing />
                    </StyledWrapper>
                  </td>
                </tr>
                <tr>
                  <td valign="bottom">
                    <Footer />
                  </td>
                </tr>
              </tbody>
          </Container>
        </BrowserRouter>
      </ThemeProvider>
    </CookiesProvider>
  );
}