import React from "react";
import { Button } from '@material-ui/core';

export default () => {
  return (
    <React.Fragment>
        <p>
          De programmeer-elf is een nieuwe programmeertaal aan het leren.
          Hij heeft nog niet alles onder de knie, maar hij kan wel pakjes genereren.
          Kan jij als hacker-elf uitvinden hoeveel pakjes er zijn?
        </p>
        <Button variant="contained" color="primary" onClick={() => window.open("levels/level13")}>Ga naar de pakjes generator</Button>
    </React.Fragment>
  );
}