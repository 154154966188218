import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";

const Wrapper = styled("div")`
  margin-top: 5em;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    text-align: center;
    margin-bottom: 16px;
  }

  img {
      max-width: 100%;
  }
`;

export default () => {
  return (
    <Wrapper>
        <Typography color="primary" variant="h1">Oops.. verkeerde afslag</Typography>
        <img src={process.env.PUBLIC_URL + '/images/lego-elf.jpg'} alt="Elf in de problemen" />
    </Wrapper>
  );
}