import React, { useState, useEffect } from "react";
import {
    Button,
    Dialog,
    TextField,
    DialogContent
} from "@material-ui/core";
import styled from "styled-components";

import CloseButton from "./CloseButtonComponent";
import useRequestHandler from "hooks/useRequestHandler";
import http from "http-common";

const StyledButton = styled(Button)`
    && {
        color: #e0ffc8;
    }
`;

const StyledTextField = styled(TextField)`
    && {
        margin-bottom: 1em;
    }
`;

const StyledDialogContent = styled(DialogContent)`
    display: flex;
    flex-direction: column;
`;

const Wrapper = styled("div")`
    height: 2em;

    button {
        top: 6px!important;
    }
`;

export default ({open, handleClose, unlockLevel, level}) => {
    const {isLoading, error, response, handleRequest} = useRequestHandler();
    const [input, setInput] = useState("");
    const [incorrect, setIncorrect] = useState(false);

    useEffect(() => {
        if (error || isLoading) return;

        if (response && response.status === 200){
            if (response.data.result === true) {
                unlockLevel();
                handleClose();
            }
            else {
                setIncorrect(true);
            }
        }
      }, [response, error, isLoading]);

    const checkPassword = () => {
        handleRequest(http.post("index.php", {
            type: "validate",
            level: level,
            password: input
        }));
    };

    const closeHandler = () => {
        setInput("");
        setIncorrect(false);
        handleClose();
    };

    const handleKeypress = e => {
      if (e.charCode === 13) {
        checkPassword();
      }
    };

    return (
        <Dialog onClose={closeHandler} open={open}>
            <StyledDialogContent>
                <Wrapper>
                    <CloseButton handleClose={closeHandler}/>
                </Wrapper>
                <StyledTextField label="wachtwoord"
                                 variant="outlined"
                                 value={input} onChange={(event) => setInput(event.target.value.toLowerCase())} 
                                 error={incorrect}
                                 helperText={incorrect ? "Onjuist password" : " " }
                                 autoFocus={true}
                                 onKeyPress={handleKeypress}/>
                <StyledButton variant="contained" 
                                color="primary"
                                onClick={() => checkPassword()}>
                    Volgende dag vrijgeven!
                </StyledButton>
            </StyledDialogContent>
        </Dialog>
    );
};
