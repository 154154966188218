import React from "react";
import { Switch, Route } from "react-router-dom";

import HomePage from "pages/HomePage";
import FinishPage from "pages/FinishPage";
import NotFoundPage from "pages/NotFoundPage";

export default () => {
  return (
    <Switch>
      <Route component={HomePage} path="/" exact />
      <Route component={FinishPage} path="/dsfkms983rrjndg-finish" exact />
      <Route component={NotFoundPage} />
    </Switch>
  );
};
