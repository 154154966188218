import React from "react";
import { Button } from '@material-ui/core';

export default () => {
  return (
    <React.Fragment>
        <p>
          De slimme computer-assistent-elf doet het een stuk beter, maar heeft nog wel wat te leren.
          Ze heeft in haar HTML cursus iets anders geleerd.
          Kan jij als hacker-elf kijken of het nu wel veilig is?
        </p>
        <Button variant="contained" color="primary" onClick={() => window.open("levels/level11")}>Ga naar het geheime notitieblok</Button>
    </React.Fragment>
  );
}