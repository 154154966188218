import React from "react";

export default () => {
  return (
    <React.Fragment>
        <p>
          Je hebt de kerstman weer verteld dat hij een sterker wachtwoord moet hebben.
          Hij heeft er nu zelfs speciale tekens aan toegevoegd!
        </p>
    </React.Fragment>
  );
}