import React from "react";
import styled from "styled-components";

const StyledAppBar = styled("div")`
    width: Calc((100%) - 2em);
    background-color: #333333;
    padding: 1em;
    color: #bbbbbb;
    text-align: center;

    a {
        text-decoration: none;
        color: #bbbbbb;
    }
    a:hover {
        text-decoration: underline;
    }
`;

export default () => (
    <StyledAppBar>
        © Stichting Hack in the Class. Alle rechten voorbehouden. Realisatie door <a href="https://ellenlangelaar.nl" rel="noreferrer" target="_blank">ellenlangelaar.nl</a>
    </StyledAppBar>
  );