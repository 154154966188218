import React from "react";
import styled from "styled-components";

const Memo = styled("div")`
  margin: 2em 1em 1em;
  border: 1px solid grey;
  padding: 1em;
`;

export default () => {
  return (
    <React.Fragment>
        <p>
          De elf van geheime boodschappen heeft een bericht onderschept van Sinterklaas!
          Hij denkt dat het belangrijk is. Kan jij als hacker-elf het bericht ontcijferen?
        </p>
        <Memo>
          <p>
            .-- . / --. .- .- -. / .- .- -. -- . .-. . -. / .. -. / --.. .-- .- .-.. -.-
          </p>
        </Memo>
    </React.Fragment>
  );
}