import React from "react";
import styled from "styled-components";
import { IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';

const InfoBox = styled("div")`
    position: relative;
    width: 50%;
    background-color: #d4f3f2;
    border: 2px solid #00bcb7;
    padding: 1em;
`;

const Wrapper = styled("div")`
    display: flex;
    justify-content: flex-end;

    &.hidden {
        display: none;
    }

    a {
        color: #00bcb7;
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }
`;

const Close = styled(IconButton)`
    && {
        position: absolute;
        right: 0px!important;
        top: -1px;
        color: #9e9e9e;
    }
`;

const Info = styled(InfoIcon)`
    && {
        color: #00bcb7;
    }
`;

const InfoButton = styled(Info)`
    float: right;

    &:hover {
        color: #047b78;
        cursor: pointer;
    }

    &.hidden {
        display: none;
    }
`;

export default ({ show, handleToggleShow }) => (
    <React.Fragment>
        <InfoButton className={show ? "hidden" : ""} onClick={handleToggleShow} />
        <Wrapper className={show ? "" : "hidden"}>
        <InfoBox>
            <Info />
            <Close aria-label="close" onClick={handleToggleShow}>
                <CloseIcon />
            </Close>
            <p>
                Welkom bij de Hack in the Class Advent Calendar 2020! <br/><br/>

                Elke dag heb je de kans om het wachtwoord te achterhalen voor de volgende dag.
                Deze kan je dan in het wachtwoord veld invoeren na op het slotje te hebben geklikt.
                Word jij op 24 december de grote winner en ontgrendel je alle dagen!? <br/><br/>

                Zit je helemaal vast of heb je andere vragen, mail dan naar <a href="mailto:info@hackintheclass.nl">info@hackintheclass.nl</a> of neem contact op
                via het IRC kanaal irc.smurfnet.ch (kanaal: #hitc).
            </p>
        </InfoBox>
    </Wrapper>
    </React.Fragment>
  );