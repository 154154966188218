import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";

const StyledAppBar = styled(AppBar)`
  flex-grow: 1;
  && {
    color: #e0ffc8;
    padding: 1em;
  }
  img {
    width: 197px;
    margin-right: 1em;
  }
`;

const StyledTitle = styled(Typography)`
  flex-grow: 1;
`;

export default () => (
    <StyledAppBar position="fixed">
      <Toolbar>
        <a href="https://hackintheclass.nl/" target="_blank" rel="noreferrer">
          <img src={process.env.PUBLIC_URL + '/images/logo.png'} alt="Hack in the Class logo"/>
        </a>
        <StyledTitle variant="h6">Advent Calendar</StyledTitle>
      </Toolbar>
    </StyledAppBar>
  );
