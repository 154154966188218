import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import Confetti from 'react-dom-confetti';

const Wrapper = styled("div")`
  margin-top: 5em;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    text-align: center;
  }
`;

const StyledConfetti = styled(Confetti)`
  z-index: 9999;
  position: absolute!important;
  height: 1em;
  width: 50%;
  bottom: 50%;
  right: 0;
`;

export default () => {
  const [throwConfetti, setThrowConfetti] = useState(false);

  setTimeout(() => {
    setThrowConfetti(!throwConfetti)
  }, 500);

  return (
    <Wrapper>
        <Typography color="primary" variant="h1">Goed gedaan!</Typography>
        <Typography color="primary" variant="h1">Je hebt alle dagen ontgrendeld!</Typography>

        <StyledConfetti config={{spread: 100}} active={throwConfetti} />
    </Wrapper>
  );
}