import React from "react";
import { Button } from '@material-ui/core';

export default () => {
  return (
    <React.Fragment>
        <p>
          De programmeer-elf is met een beter oplossing gekomen. Hij heeft het bestand op een veel geheimere plek verstopt.
        </p>
        <p>
          Kan jij zonder in te loggen het bestand 'kinderen.txt' weer bekijken?
          Kan jij uitvinden welk cadeau Malika krijgt?
        </p>
        <Button variant="contained" color="primary" onClick={() => window.open("levels/level22")}>Ga naar de administratie website</Button>
    </React.Fragment>
  );
}