import React, { useState } from "react";
import styled from "styled-components";

const Hint = styled("div")`
  position: absolute;
  bottom: 0;
  color: #cccbcb;

  p.hover:hover {
    display: none;
  }

  p.hint {
    display: none;
  }
  p.hint:hover {
    display: block;
  }
`;

export default ({text}) => {
    const [hidden, setHidden] = useState(true);

    return (
        <Hint>
            <p hidden={!hidden} onClick={() => setHidden(false)}>
                Klik hier voor een hint.
            </p>
            <p hidden={hidden}>
                Hint: {text}
            </p>
        </Hint>
      );
};