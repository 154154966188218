import React from "react";
import { Button } from '@material-ui/core';

export default () => {
  const link = process.env.REACT_APP_API_URL + "/level17.php?user=1";

  return (
    <React.Fragment>
        <p>
          Er is een nieuwe inpak-elf waar de computer-elf een account voor moet aanmaken.
          Omdat iedereen ‘elf’ heet moet hij gebruik maken van nummertjes. 
          Kan jij als hacker-elf controleren of je de straatnaam van elf 4 kan achterhalen?
        </p>
        <Button variant="contained" color="primary" onClick={() => window.open(link)}>Ga naar het elven register</Button>
    </React.Fragment>
  );
}