import React from "react";
import { Button } from '@material-ui/core';

export default () => {
  return (
    <React.Fragment>
        <p>
          De computerelf heeft het opgegeven en laat nu de slimme computer-assistent-elf het probleem oplossen.
          Ze heeft iets slims en nieuws bedacht! Kan jij als hacker-elf kijken of ze het goed heeft gedaan?
        </p>
        <Button variant="contained" color="primary" onClick={() => window.open("levels/level10")}>Ga naar het geheime notitieblok</Button>
    </React.Fragment>
  );
}