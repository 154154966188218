import { Crypt } from 'hybrid-crypto-js';

export const get = (data) => {
    // Worst private key ever
    const privateKey = '-----BEGIN RSA PRIVATE KEY-----\r\nMIICWwIBAAKBgHq+WzuhGbeOpYHb7NscN5Zv8p8IW4lnVY1/j0qbL5634+EV0FPb\r\nxqEy7CCygbGs/AYeWm9X+yZQOSirkidRNK/jDKTJ7w7kAU1vRBgcLV4TtCy+/nkE\r\nVcd2YHCXxwjBozbWREldpdYxEKX/57oFq5kUdmhFKeIROA1M3EdUccrpAgMBAAEC\r\ngYBJlA6wohP+et4wCBvmBPY1kgoI10O4DF+2oL7O5FQZevZ5mU/21Qou24281AT2\r\n81FogY2IrV76SqDZBnBobT99UqNm0YVyr1eVJ8I1ThyUv7t4JuAH1o0LxTcwVTH2\r\nNwrD2Fx8b056MxckU1cot4MJoBwC4iiHntUlU8tU9lrIuQJBAMRpCz6t2ulEUDia\r\nDLNtE/wzOvUjK7hPVHj6rWikA10tK02GaGdsoFfM/IA3dG0aXWwcCLCRPDboEeQF\r\nCAjBZocCQQCf+7Qz5S2j88MUSc3/UWBIyqu8YTUkyoySOVL4fTKb70TReYR1IRpq\r\n9dxwsPz/9PA0SWlT5/dFlDug0x++5i8PAkAD2d9rkjMZocpE4dhf6EKdDlAYoGDq\r\nEzwfxyKBS735JLqVoY4IgRu/vPgyd2PkA8FTLlWJSL+6f+Bh7StUKJ6fAkAtvdfO\r\nJq6f++hA+sEtSOUQqvHjUJ/TPx5mCIHSjmlT8Ftq0SWwH6DgCB/ZEKGlxTCQopS8\r\n6svmVvWdi/+9oy69AkEAsy7qTbOMel1ji3IsvTPxBFZ902ALvaKnyLKH9qgsrt80\r\nEWcIPd+9ivrv2NYNySN0C+kpRKoDL2ltE/97nn9htA==\r\n-----END RSA PRIVATE KEY-----\r\n';
    
    const crypt = new Crypt();
    const decrypted = crypt.decrypt(privateKey, data);
    
    return JSON.parse(decrypted.message);
}

export const set = (data) => {
    const publicKey = '-----BEGIN PUBLIC KEY-----\r\nMIGeMA0GCSqGSIb3DQEBAQUAA4GMADCBiAKBgHq+WzuhGbeOpYHb7NscN5Zv8p8I\r\nW4lnVY1/j0qbL5634+EV0FPbxqEy7CCygbGs/AYeWm9X+yZQOSirkidRNK/jDKTJ\r\n7w7kAU1vRBgcLV4TtCy+/nkEVcd2YHCXxwjBozbWREldpdYxEKX/57oFq5kUdmhF\r\nKeIROA1M3EdUccrpAgMBAAE=\r\n-----END PUBLIC KEY-----\r\n';

    const crypt = new Crypt();
    const json = JSON.stringify(data);
    const result = crypt.encrypt(publicKey, json);

    return JSON.stringify(result);
}