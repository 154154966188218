import React from "react";
import { Button } from '@material-ui/core';
import Hint from "components/HintComponent";

export default () => {
  const link = process.env.REACT_APP_API_URL + "/level19.php?stad=utrecht&cadeau=gitaar";

  return (
    <React.Fragment>
        <p>
          De programmeer-elf heeft nu een nieuwe functionaliteit toegevoegd waarmee de transport-elven kunnen vinden waar ze nog cadeau’s kunnen kopen.
          Kan jij uitvinden in welke provinciehoofdstad er nog een <b>bordspel</b> te vinden is?
        </p>
        <Button variant="contained" color="primary" onClick={() => window.open(link)}>Ga naar de inkoop website</Button>
        <Hint text="Provinciehoofdsteden van Nederland zijn: Maastricht, 's-Hertongenbosch, Middelburg, Den Haag, Haarlem, Utrecht, Arnhem, Lelystad, Zwolle, Assen, Leeuwarden en Groningen." />
    </React.Fragment>
  );
}