import React from "react";
import { Button } from '@material-ui/core';

export default () => {
  return (
    <React.Fragment>
        <p>
          De computerelf is heel vergeetachtig en schrijft altijd alles op in zijn geheime notitieblok.
          Maar hij heeft een fout gemaakt zonder dat hij het in de gaten had! 
          Kan jij als hacker-elf zijn fout ontdekken?
        </p>
        <Button variant="contained" color="primary" onClick={() => window.open("levels/level8")}>Ga naar het geheime notitieblok</Button>
    </React.Fragment>
  );
}