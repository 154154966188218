import React from "react";
import { Button } from '@material-ui/core';

export default () => {
  return (
    <React.Fragment>
        <p>
          De administratie-elf heeft het bestand nu op een geheime plek gezet, zodat niemand er meer bij kan! 
        </p>
        <p>
          Kan jij zonder in te loggen het bestand 'kinderen.txt' weer bekijken?
          Als je kan vertellen welk cadeau Mohammed krijgt dan heeft hij niet zo goed zijn werk gedaan.
        </p>
        <Button variant="contained" color="primary" onClick={() => window.open("levels/level21")}>Ga naar de administratie website</Button>
    </React.Fragment>
  );
}