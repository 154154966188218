import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useCookies } from 'react-cookie';
import Confetti from 'react-dom-confetti';

import { get, set } from 'helpers/crypt';
import Box from "components/BoxComponent";
import Level1 from "levels/Level1";
import Level2 from "levels/Level2";
import Level3 from "levels/Level3";
import Level4 from "levels/Level4";
import Level5 from "levels/Level5";
import Level6 from "levels/Level6";
import Level7 from "levels/Level7";
import Level8 from "levels/Level8";
import Level9 from "levels/Level9";
import Level10 from "levels/Level10";
import Level11 from "levels/Level11";
import Level12 from "levels/Level12";
import Level13 from "levels/Level13";
import Level14 from "levels/Level14";
import Level15 from "levels/Level15";
import Level16 from "levels/Level16";
import Level17 from "levels/Level17";
import Level18 from "levels/Level18";
import Level19 from "levels/Level19";
import Level20 from "levels/Level20";
import Level21 from "levels/Level21";
import Level22 from "levels/Level22";
import Level23 from "levels/Level23";
import Level24 from "levels/Level24";
import InformationBox from "components/InformationBoxComponent";

const Calendar = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const StyledConfetti = styled(Confetti)`
  z-index: 9999;
  position: absolute!important;
  height: 1em;
  width: 50%;
  bottom: 20%;
  right: 0;
`;

const cookieOptions = {
  expires: new Date("2050-01-01")
};

export default () => {
  const [throwConfetti, setThrowConfetti] = useState(false);
  const [value, setValue] = useState(0);
  const [cookie, setCookie] = useCookies(['hitc-advent-calender']);
  const [levels, setLevels] = useState(undefined);
  const [showInfoBox, setShowInfoBox] = useState(false);

  useEffect(() => {
    if (levels === undefined){
      if (cookie.id !== undefined)
        setLevels(get(cookie.id));
      else {
        // Level 1 is always unlocked
        updateLevels([1]);
      }

      if (cookie.showInfoBox === undefined || cookie.showInfoBox === true) {
        setShowInfoBox(true);
      }
    }
  }, [cookie]);

  const unlockLevel = (level) => {
    setThrowConfetti(true);

    let unlocked = levels;
    if (unlocked === undefined)
      unlocked = [level];
    else
      unlocked.push(level);

    updateLevels(unlocked);

    // Reset confetti
    setTimeout(() => setThrowConfetti(false), 100);
  };

  const updateLevels = (levels) => {
    setLevels(levels);
    setCookie("levels", levels, cookieOptions);
    setCookie("id", set(levels), cookieOptions);
    forceRerender();
  };

  const isUnlocked = (level) => {
    if (levels === undefined) return false;

    return levels.includes(level);
  };

  const isCompleted = (level) => {
    if (!isUnlocked(level)) return false;

    return levels.includes(level + 1);
  };

  const forceRerender = () => setValue(value => ++value);

  const handleToggleInfoBox = () => {
    setCookie("showInfoBox", !showInfoBox, cookieOptions);
    setShowInfoBox(!showInfoBox);
  };

  return (
    <React.Fragment>
      <InformationBox show={showInfoBox} handleToggleShow={handleToggleInfoBox} />
      <Calendar>
        <Box level={1} open={() => true} completed={isCompleted}><Level1 /></Box>
        <Box level={2} unlockLevel={unlockLevel} open={isUnlocked} completed={isCompleted}><Level2 /></Box>
        <Box level={3} unlockLevel={unlockLevel} open={isUnlocked} completed={isCompleted}><Level3 /></Box>
        <Box level={4} unlockLevel={unlockLevel} open={isUnlocked} completed={isCompleted}><Level4 /></Box>
        <Box level={5} unlockLevel={unlockLevel} open={isUnlocked} completed={isCompleted}><Level5 /></Box>
        <Box level={6} unlockLevel={unlockLevel} open={isUnlocked} completed={isCompleted} largeDialog={true}><Level6 /></Box>
        <Box level={7} unlockLevel={unlockLevel} open={isUnlocked} completed={isCompleted} largeDialog={true}><Level7 /></Box>
        <Box level={8} unlockLevel={unlockLevel} open={isUnlocked} completed={isCompleted}><Level8 /></Box>
        <Box level={9} unlockLevel={unlockLevel} open={isUnlocked} completed={isCompleted}><Level9 /></Box>
        <Box level={10} unlockLevel={unlockLevel} open={isUnlocked} completed={isCompleted}><Level10 /></Box>
        <Box level={11} unlockLevel={unlockLevel} open={isUnlocked} completed={isCompleted}><Level11 /></Box>
        <Box level={12} unlockLevel={unlockLevel} open={isUnlocked} completed={isCompleted}><Level12 /></Box>
        <Box level={13} unlockLevel={unlockLevel} open={isUnlocked} completed={isCompleted}><Level13 /></Box>
        <Box level={14} unlockLevel={unlockLevel} open={isUnlocked} completed={isCompleted}><Level14 /></Box>
        <Box level={15} unlockLevel={unlockLevel} open={isUnlocked} completed={isCompleted}><Level15 /></Box>
        <Box level={16} unlockLevel={unlockLevel} open={isUnlocked} completed={isCompleted}><Level16 /></Box>
        <Box level={17} unlockLevel={unlockLevel} open={isUnlocked} completed={isCompleted}><Level17 /></Box>
        <Box level={18} unlockLevel={unlockLevel} open={isUnlocked} completed={isCompleted}><Level18 /></Box>
        <Box level={19} unlockLevel={unlockLevel} open={isUnlocked} completed={isCompleted}><Level19 /></Box>
        <Box level={20} unlockLevel={unlockLevel} open={isUnlocked} completed={isCompleted}><Level20 /></Box>
        <Box level={21} unlockLevel={unlockLevel} open={isUnlocked} completed={isCompleted}><Level21 /></Box>
        <Box level={22} unlockLevel={unlockLevel} open={isUnlocked} completed={isCompleted}><Level22 /></Box>
        <Box level={23} unlockLevel={unlockLevel} open={isUnlocked} completed={isCompleted}><Level23 /></Box>
        <Box level={24} unlockLevel={unlockLevel} open={isUnlocked} completed={isCompleted}><Level24 /></Box>
        <StyledConfetti config={{spread: 100}} active={throwConfetti} />
      </Calendar>
    </React.Fragment>
  );
}