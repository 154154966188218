import React from "react";
import { Button } from '@material-ui/core';

export default () => {
  return (
    <React.Fragment>
        <p>
          Door alle kerstdrukte zijn alle kerstelven iets heel belangrijks vergeten. Kan jij als hacker-elf achterhalen wat?
        </p>
        <Button variant="contained" color="primary" onClick={() => window.open("levels/level24")}>Ga naar de kerstavond website</Button>
    </React.Fragment>
  );
}