import React from "react";
import styled from "styled-components";

import Hint from "components/HintComponent";

const Image = styled("img")`
  width: 100%;
`;

export default () => {
  return (
    <React.Fragment>
        <p>
          De foto-elf heeft en mooie foto gemaakt voor de kerstkaarten.
          Hij heeft alleen de locatie een beetje weggegeven.
          Kan jij als hacker-elf achterhalen waar de foto is genomen?
        </p>
        
        <Image src={process.env.PUBLIC_URL + '/images/kerstkaart.jpg'} />

        <Hint text="Sla het bestand op op je computer en ga opzoek naar de metadata." />
    </React.Fragment>
  );
}