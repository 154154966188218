import React from "react";
import styled from "styled-components";

const Memo = styled("div")`
  margin: 2em 1em 1em;
  border: 1px solid grey;
  padding: 1em;
`;

export default () => {
  return (
    <React.Fragment>
        <p>
          Één van de elven is een lolbroek. Hij heeft een grap uitgehaald en een bericht gestuurd naar alle elven.
          De andere elven zijn boos want ze kunnen het niet oplossen. Kan jij als hacker-elf dit probleem oplossen?
        </p>
        <Memo>
          <h1>Bericht aan alle elven!</h1>
          <p>
            Het wachtwoord voor dag 2 is: tsrektehsinegad42revo
          </p>
        </Memo>
    </React.Fragment>
  );
}