import React from "react";
import { Button } from '@material-ui/core';

export default () => {
  const link = process.env.REACT_APP_API_URL + "/level18.php?user=1&cadeau=auto";

  return (
    <React.Fragment>
        <p>
          De programmeer-elf heeft nieuwe functionaliteit toegevoegd aan het elven register. 
          Hiermee kan de inpak-elf de voorraad van pakjes controleren.
          Alleen de volgende pakjes staan nog maar in het register: auto, fiets, kleurpotloden, spelcomputer, skateboard.
          Kan jij als hacker-elf achterhalen van welk cadeau er geen voorraad meer is?
        </p>
        <Button variant="contained" color="primary" onClick={() => window.open(link)}>Ga naar het elven register</Button>
    </React.Fragment>
  );
}