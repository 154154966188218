import React from "react";
import { IconButton } from "@material-ui/core";
import styled from "styled-components";
import CloseIcon from '@material-ui/icons/Close';

const Close = styled(IconButton)`
    && {
        position: absolute;
        right: 16px;
        top: 16px;
        color: #9e9e9e;
    }
`;

export default ({ handleClose }) => {
    return (
        <Close aria-label="close" onClick={handleClose}>
            <CloseIcon />
        </Close>
    );
};