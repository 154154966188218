import React from "react";

export default () => {
  return (
    <React.Fragment>
        <p>
            De kerstman heeft op jouw aanraden een beter wachtwoord ingesteld en cijfers toegevoegd, maar heeft deze niet opgeslagen in zijn wachtwoordkluis.
            Hierdoor is hij hem weer vergeten. Kan jij nog een keer achterhalen wat zijn wachtwoord is? Dit is meteen je wachtwoord voor de volgende dag!
        </p>
    </React.Fragment>
  );
}