import React from "react";
import styled from "styled-components";
import { Link } from '@material-ui/core';

const Memo = styled("div")`
  margin: 2em 1em 1em;
  border: 1px solid grey;
  padding: 1em;

  div {
      display: flex;
      justify-content: center;
  }

  table, th, td {
    border: 1px solid black;
  }

  table {
    border-collapse: collapse;
  }

  td {
      padding: 6px;
  }
`;

export default () => {
    const print = () => {
        var printWindow = window.open("levels/level3/dag3.pdf");
        printWindow.print();
    };

    return (
        <React.Fragment>
            <p>
                De elf van geheime boodschappen heeft een bericht gevonden van lolbroek-elf.
                Hij heeft nog iets extra’s gekregen, maar weet niet wat hij daarmee kan.
                Kan jij als hacker-elf helpen met ontcijferen?
            </p>
            <Memo>
                <p>
                    Voor mijn volgende grap moet ik het wachtwoord voor dag 4 onthouden:
                </p>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>1</th>
                                <th>2</th>
                                <th>3</th>
                                <th>4</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>k</td>
                                <td>s</td>
                                <td>r</td>
                                <td>e</td>
                            </tr>
                            <tr>
                                <td>t</td>
                                <td>2</td>
                                <td>n</td>
                                <td>i</td>
                            </tr>
                            <tr>
                                <td>0</td>
                                <td>v</td>
                                <td>1</td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <td>a</td>
                                <td>o</td>
                                <td>t</td>
                                <td>l</td>
                            </tr>
                            <tr>
                                <td>p</td>
                                <td>n</td>
                                <td>e</td>
                                <td>e</td>
                            </tr>
                            <tr>
                                <td>v</td>
                                <td>j</td>
                                <td>i</td>
                                <td>r</td>
                            </tr>
                            <tr>
                                <td>d</td>
                                <td>!</td>
                                <td>g</td>
                                <td>a</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Memo>

            <p>
                Hint: Je kan <Link onClick={print}>HIER</Link > het bestand uitprinten en uitknippen.
            </p>
        </React.Fragment>
    );
}