import React from "react";
import { Button } from '@material-ui/core';

export default () => {
  return (
    <React.Fragment>
        <p>
          De computerelf heeft geleerd van zijn fout, maar heeft nu iets anders bedacht om het moeilijker te maken.
          Kan jij als hacker-elf controleren of hij het nu wel goed heeft gedaan?
        </p>
        <Button variant="contained" color="primary" onClick={() => window.open("levels/level9")}>Ga naar het geheime notitieblok</Button>
    </React.Fragment>
  );
}