import React from "react";
import styled from "styled-components";

const Memo = styled("div")`
  margin: 2em 1em 1em;
  border: 1px solid grey;
  padding: 1em;
`;

export default () => {
  return (
    <React.Fragment>
        <p>
          De geheime-boodschappen-elf heeft nu een bericht onderschept van computer-elf.
          Hij vindt het maar raar. Kan jij hem weer helpen ontcijferen?
        </p>
        <Memo>
          <p>
            in%202021%20m03t3n%20w3%20g33n%20bas364%20m33r%20g3bruik3n
          </p>
        </Memo>
    </React.Fragment>
  );
}