import React from "react";

export default () => {
  return (
    <React.Fragment>
        <p>
          De kerstman is zijn wachtwoord vergeten en heeft jou als hacker-elf om hulp gevraagd.
          Kan jij achterhalen wat zijn wachtwoord is? Dit is meteen je wachtwoord voor de volgende dag!
        </p>
    </React.Fragment>
  );
}