import React from "react";
import styled from "styled-components";
import { Link } from '@material-ui/core';

const Memo = styled("div")`
  margin: 2em 1em 1em;
  border: 1px solid grey;
  padding: 1em;

  div {
      display: flex;
      justify-content: center;
  }

  table, th, td {
    border: 1px solid black;
  }

  table {
    border-collapse: collapse;
  }

  td {
      padding: 6px;
  }
`;

export default () => {
    return (
        <React.Fragment>
            <p>
                De elf van geheime boodschappen heeft een bericht gevonden van lolbroek-elf maar snapt er helemaal niks van.
                Kan jij als hacker-elf helpen met ontcijferen?
            </p>
            <Memo>
                <p>
                    Voor mijn volgende grap moet ik het wachtwoord voor dag 3 onthouden:
                </p>
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td>k</td>
                                <td>e</td>
                                <td>r</td>
                            </tr>
                            <tr>
                                <td>s</td>
                                <td>t</td>
                                <td>b</td>
                            </tr>
                            <tr>
                                <td>a</td>
                                <td>a</td>
                                <td>r</td>
                            </tr>
                            <tr>
                                <td>d</td>
                                <td>e</td>
                                <td>n</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Memo>
        </React.Fragment>
    );
}