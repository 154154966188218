import React from "react";
import styled from "styled-components";

import Hint from "components/HintComponent";

const Memo = styled("div")`
  margin: 2em 1em 1em;
  border: 1px solid grey;
  padding: 1em;
`;


export default () => {
  return (
    <React.Fragment>
        <p>
          De geheime-boodschappen-elf heeft opnieuw een bericht onderschept maar deze keer van de paashaas!
          Hij denkt dat dit belangrijk is. Kan jij het bericht weer ontcijferen als hacker-elf?
        </p>
        <Memo>
          <p>
            Jr fynna qr rvrera ina 2021 bc va Nzfgreqnz
          </p>
        </Memo>
        <Hint text="Zoek op het internet naar het 'caesaralfabet'." />
    </React.Fragment>
  );
}