import React from "react";
import { Button } from '@material-ui/core';

export default () => {
  return (
    <React.Fragment>
        <p>
          De slimme computer-assistent-elf heeft een plaatje ontvangen van de transport-elf van een landkaart.
          Hierop staat de geheime locatie van de pakjesfabriek.
          Kan jij als hacker-elf controleren of de locatie van de pakjesfabriek geheim blijft?
        </p>
        <Button variant="contained" color="primary" onClick={() => window.open("levels/level12")}>Ga naar het plaatje</Button>
    </React.Fragment>
  );
}