import React from "react";
import styled from "styled-components";

import Hint from "components/HintComponent";

const Memo = styled("div")`
  margin: 2em 1em 1em;
  border: 1px solid grey;
  padding: 1em;

`;

export default () => {
  return (
    <React.Fragment>
        <p>
          De geheime-boodschappen-elf heeft nu een bericht onderschept van de lolbroek-elf.
          Hij heeft iets gedaan met de belangrijke lijst van technische cadeau’s.
          Hij snapt het helemaal niet meer. Kan jij hem weer helpen ontcijferen?
        </p>
        <Memo>
          <p>
            c3BlbGNvbXB1dGVyLCBsYXB0b3AsIHRlY2huaXNjaCBsZWdvLCB0YWJsZXQsIGhvdmVyYm9hcmQ=
          </p>
        </Memo>
        <Hint text="Zoek op het internet naar 'hash conversie naar ASCII'." />
    </React.Fragment>
  );
}