import React, { useState, useEffect } from "react";
import {
    Button,
    Typography,
} from "@material-ui/core";
import LockIcon from '@material-ui/icons/Lock';
import styled from "styled-components";

import Countdown from "./CountdownComponent";
import ChallengeDialog from "./ChallengeDialogComponent";
import PasswordDialog from "./PasswordDialogComponent";

const Container = styled("div")`
    margin: 1em;
    height: 10em;
    width: 10em;
`;

const Box = styled("div")`
    height: 10em;
    width: 10em;
    border: 5px solid #00bcb7;
`;

const Door = styled("div")`
    height: 10em;
    width: 10em;
    background-color: #00bcb7;
    transform-origin: right;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #e0ffc8;
    position: relative;
    z-index: 999;

    &&:hover {
        transform: perspective(1200px) rotateY(50deg);
        transition: .45s;
        background-color: #00a7a2;
    }

    &&.open {
        transform: perspective(1200px) rotateY(100deg);
        transition: .45s;
        background-color: #00a7a2;
        color: #00a7a2;
    }
`;

const Content = styled("div")`
    margin-top: -10em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    -webkit-box-shadow: inset 14px 14px 4px -9px rgba(97,97,97,0.8);
    -moz-box-shadow: inset 14px 14px 4px -9px rgba(97,97,97,0.8);
    box-shadow: inset 14px 14px 4px -9px rgba(97,97,97,0.8);

    &.locked {
        background-color: #7d7d7d;
    }
`;

const WhiteSpaceFixer = styled("div")`
    height: 100%;
    -webkit-box-shadow: 0px 0px 0px 2px rgba(0,188,183,1);
    -moz-box-shadow: 0px 0px 0px 2px rgba(0,188,183,1);
    box-shadow: 0px 0px 0px 2px rgba(0,188,183,1);
`;

const Trophy = styled("img")`
    width: 50%;
    -webkit-animation:spin 2s linear 0s;
    -moz-animation:spin 2s linear 0s;
    animation:spin 2s linear 0s;
    
    @-moz-keyframes spin { 50% { -moz-transform: rotateY(360deg); } }
    @-webkit-keyframes spin { 50% { -webkit-transform: rotateY(360deg); } }
    @keyframes spin { 50% {  -webkit-transform: rotateY(360deg); transform:rotateY(360deg); } }

    &:hover {
        cursor: pointer;
        opacity: 0.9;
    }
`;

export default (props) => {
    const [isDoorOpen, setIsDoorOpen] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [showChallenge, setShowChallenge] = useState(false);
    const [startable, setStartable] = useState(undefined);
    const [unlockable, setUnlockable] = useState(undefined);
    const today = new Date();

    useEffect(() => {
        if (isOpen()) {
            setIsDoorOpen(true);
        }

        if (startable === undefined) setStartable(isStartable());
        if (unlockable === undefined) setUnlockable(isUnlockable());
      }, [props.open]);

    const isOpen = () => props.open(props.level);
    const isCompleted = () => props.completed !== undefined && props.completed(props.level);

    const shouldIgnoreDateLock = () => {
         if (today.getMonth() !== 11 || process.env.REACT_APP_TIME_LOCK_ENABLED === "false"){
             console.log("ignore");
            return true;
        }
    }

    const isUnlockable = () => {
        if (shouldIgnoreDateLock()) return true;

        return parseInt(props.level) <= today.getDate() + 1;
    };

    const isStartable = () => {
        if (shouldIgnoreDateLock()) return true;

        return parseInt(props.level) <= today.getDate();
    };

    const handleDialogClose = (value) => {
        setShowDialog(false);
    };

    const handleChallengeClose = (value) => {
        setShowChallenge(false);
    };

    const handleStartChallenge = () => {
        // Either show the dialog or open the level page
        if (props.children !== undefined)
            setShowChallenge(true);
        else
            window.open("levels/level" + props.level + "/index.html" , '_blank')
    };

    return (
        <Container>
            <Box>
                <Door className={isDoorOpen ? 'open': null} onClick={() => setIsDoorOpen(!isDoorOpen)}>
                    <Typography variant="h2">{props.level}</Typography>
                </Door>

                <WhiteSpaceFixer>
                    <Content className={!isOpen() ? 'locked': null}>
                        { !isOpen() &&  
                            <Button variant="contained" 
                                    color="secondary"
                                    disabled={!unlockable}
                                    onClick={() => setShowDialog(true)}>
                                <LockIcon />
                            </Button>
                        }
                        { isOpen() && !isCompleted() && 
                            <React.Fragment>
                                <Countdown enabled={!startable} day={props.level} />
                                <Button variant="contained" 
                                    color="primary"
                                    disabled={!startable}
                                    onClick={handleStartChallenge}>
                                    Start
                                </Button>
                            </React.Fragment>
                        }
                        { isOpen() && isCompleted() && 
                            <Trophy src={process.env.PUBLIC_URL + '/images/trophy.png'} alt="Trofee" onClick={handleStartChallenge}/>
                        }
                    </Content>
                </WhiteSpaceFixer>
            </Box>
            
            <PasswordDialog open={showDialog} handleClose={handleDialogClose} level={props.level} unlockLevel={() => props.unlockLevel(props.level)} />

            <ChallengeDialog open={showChallenge} handleClose={handleChallengeClose} challengeTitle={"Dag " + props.level} large={props.largeDialog}>
                {props.children}
            </ChallengeDialog>
        </Container>
    );
};
