import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent
} from "@material-ui/core";
import styled from "styled-components";

import CloseButton from "./CloseButtonComponent";

const StyledDialog = styled(Dialog)`
    &.large .MuiDialog-paperWidthSm {
        max-width: 900px;
    }
`;

const StyledDialogContent = styled(DialogContent)`
    min-height: 50vH;
`;

export default (props) => {
    const {challengeTitle, open, handleClose} = props;

    return (
        <StyledDialog onClose={handleClose} open={open} className={props.large ? "large" : ""}>
            <CloseButton handleClose={handleClose}/>
            <DialogTitle>{challengeTitle}</DialogTitle>
            <StyledDialogContent>
                {props.children}
            </StyledDialogContent>
        </StyledDialog>
    );
};