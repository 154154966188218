import React, { useState, useEffect } from "react";
import styled from "styled-components";

const StyledSpan = styled("span")`
    margin-top: -1.3rem;

    &.hidden {
        display: none;
    }
`;

export default ({enabled, day}) => {
    const [remainingTime, setRemainingTime] = useState("00:00:00");
    const countDownDate = new Date("2020-12-" + day).getTime();
    let timer;

    useEffect(() => {
        if (timer !== undefined || !enabled) return;        

        timer = setInterval(() => {
            var now = new Date().getTime();
            var distance = countDownDate - now;
            
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            
            setRemainingTime(addZero(hours) + ":" + addZero(minutes) + ":" + addZero(seconds));
        }, 1000);

        return () => clearInterval(timer);
    });

    const addZero = (number) => {
        return number < 10 ? "0" + number : number;
    };

    return (
        <StyledSpan className={enabled ? "" : "hidden"}>
            { remainingTime }
        </StyledSpan>
    );
};