import React from "react";
import { Button } from '@material-ui/core';

export default () => {
  return (
    <React.Fragment>
        <p>
          De administratie-elf heeft een lijst gemaakt met alle kinderen die dit jaar wat extra’s verdienen.
          Deze lijst heeft hij online gezet zodat de inpak-elven kunnen controleren wie welk cadeau krijgt.
        </p>
        <p>
          Kan jij zonder in te loggen het bestand 'kinderen.txt' bekijken?
          Als je kan vertellen welk cadeau Suzanne krijgt dan heeft hij niet zo goed zijn werk gedaan.
        </p>
        <Button variant="contained" color="primary" onClick={() => window.open("levels/level20")}>Ga naar de administratie website</Button>
    </React.Fragment>
  );
}